import Cookies from 'js-cookie'

export default {
  removeCookies: () => {
    Object
      .keys(Cookies.get())
      .filter(cookie => cookie !== 'OptanonAlertBoxClosed')
      .filter(cookie => cookie !== 'OptanonConsent')
      .forEach(cookie => {
        const domains = process.env.VUE_APP_COOKIE_DOMAIN.split('|')

        domains.forEach(domain => {
          Cookies.remove(cookie, {
            // path & domain attributes are mandatory for removal to work
            domain,
            path: '/'
          })
        })
      })
  }
}
